/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* Custom styles
-------------------------------------------------- */

.table-transparent tbody tr:nth-of-type(odd) {
    background-color: transparent;
}

.table-transparent tbody tr td {
    padding: 6px;
}

.table-striped-white > tbody > tr:nth-child(even) > td,
.table-striped-white > tbody > tr:nth-child(even) > th {
    background-color: white;
}

/* hide parts of the website in case it is verry narrow */ 
@media only screen and (max-width: 767px) {
  .hide-sm {
    visibility: hidden;
  }
}

.isRotated svg { 
  transition: all .3s ease-in;
  transform: rotate(-90deg); 
}
.isNotRotated svg { 
  transition: all .3s ease-in;
  transform: rotate(0deg); 
}

.list-group-item .swipeable-list-item__leading-actions {
  margin-top: -0.75rem;
  margin-left: -1.25rem;
}

.swipe-action .action-content {
  padding: 0.75rem 1.25rem;
  align-items: center;
  display: flex;
}
